/*General*/
@import url('https://fonts.googleapis.com/css2?family=DotGothic16&family=Zen+Dots&display=swap');
html, body {
    width: 100%;
    height: 100%;
    font-family: 'DotGothic16', Arial, sans-serif;
}
body {
    background-color: #171614;
    margin: 0;
    overflow-x: hidden;
    color: white;
}
.noise-background {
    position: fixed;
    top: -50%;
    left: -50%;
    right: -50%;
    bottom: -50%;
    width: 200%;
    height: 200vh;
    background: transparent url('../images/noise-transparent.png') repeat 0 0;
    animation: bg-animation .2s infinite;
    opacity: .9;
    visibility: visible;
    z-index: -1;
}
@keyframes bg-animation {
    0% { transform: translate(0,0) }
    10% { transform: translate(-5%,-5%) }
    20% { transform: translate(-10%,5%) }
    30% { transform: translate(5%,-10%) }
    40% { transform: translate(-5%,15%) }
    50% { transform: translate(-10%,5%) }
    60% { transform: translate(15%,0) }
    70% { transform: translate(0,10%) }
    80% { transform: translate(-15%,0) }
    90% { transform: translate(10%,5%) }
    100% { transform: translate(5%,0) }
}
header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 25px 0;
    box-sizing: border-box;
    --color: #FAFAFA;
}
.container {
    color: var(--color);
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
}
.center {
    font-weight: normal;
    text-align: center;
    width: 100%;
}
.stack {
    display: grid;
    grid-template-columns: 1fr;
}
.stack span {
    font-family: 'Zen Dots', sans-serif;
    font-weight: bold;
    grid-row-start: 1;
    grid-column-start: 1;
    font-size: 4rem;
    --stack-height: calc(100% / var(--st) - 1px);
    --inverse-i: calc(calc(var(--st) - 1) - var(--i));
    --clip-top: calc(var(--stack-height) * var(--i));
    --clip-bottom: calc(var(--stack-height) * var(--inverse-i));
    clip-path: inset(var(--clip-top) 0 var(--clip-bottom) 0);
    animation: stack 340ms cubic-bezier(.46,.29,0,1.24) 1 backwards calc(var(--i) * 120ms), glitch 2s ease infinite 2s alternate-reverse;
}
.stack span:nth-child(odd) { --glitch-translate: 8px; }
.stack span:nth-child(even) { --glitch-translate: -8px; }
@keyframes stack {
    0% {
        opacity: 0;
        transform: translateX(-50%);
        text-shadow: -2px 3px 0 red, 2px -3px 0 blue;
    };
    60% {
        opacity: 0.5;
        transform: translateX(50%);
    }
    80% {
        transform: none;
        opacity: 1;
        text-shadow: 2px -3px 0 red, -2px 3px 0 blue;
    }
    100% {
        text-shadow: none;
    }
}

@keyframes glitch {
    0% {
        text-shadow: -2px 3px 0 red, 2px -3px 0 blue;
        transform: translate(var(--glitch-translate));
    }
    2% {
        text-shadow: 2px -3px 0 red, -2px 3px 0 blue;
    }
    4%, 100% {  text-shadow: none; transform: none; }
}

footer{
    position: relative;
    width: 50%;
    margin: 0 auto;
}

footer a {
    color: white;
}

/*Game*/
.blue-background{
    background: #42BFDD;
}
.board {
    position: relative;
    width: 50%;
    margin: 0 auto;
    border-top: 1px solid #ffffff24;
    border-right: 1px solid #ffffff24;
}
.row {
    display: flex;
}
.cell{
    height: 13px;
    position: relative;
    border-bottom: 1px solid #ffffff24;
    border-left: 1px solid #ffffff24;
}
.cell:before{
    opacity: 0.2;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    transition: box-shadow 0.2s linear;
}
.cell:hover:before{
    box-shadow: inset 0 0 20px white;
    transition: box-shadow 0.2s linear;
}
.cell.on{
    background-color: var(--c-color);
    box-shadow: 0 0 10px 2px var(--c-color);
}
/*Interface*/
.game-interface{
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    margin-bottom: 15px;
    justify-content: space-between;
    align-items: center;
}
.game-buttons{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.game-buttons label{
    font-weight: bold;
}
.game-button{
    border: 0;
    background-color: transparent;
    border-radius: 100%;
    cursor: pointer;
    opacity: 1;
    transition: 300ms all ease-in-out;;
}
.game-button:hover{
    opacity: 0.5;
    transform: scale(0.95);
    transition: 300ms all ease-in-out;;
}
.game-buttons {
    display: flex;
    align-items: center;
}
.game-select, .game-input {
    background-color: transparent;
    color: white;
    font-family: inherit;
    padding: 5px 15px;
    font-weight: bold;
    border: 3px solid white;
    border-radius: 20px;
    width: 130px;
}
.game-select:focus-visible, .game-input:focus-visible {
    outline: 0px;
}
.game-select option {
    color: black;
    font-family: 'Zen Dots';
}
.game-input {
    width: 50px;
    margin-left: 13px;
    margin-right: 13px;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    text-align: center;
    font-weight: normal;
}
/*Color selector*/
.colorSelector{
    position: relative;
}
.colorBlock{
    width: 50px;
    height: 50px;
    background-color: var(--c-color);
    border: 3px solid white;
    border-radius: 20px;
    transition: all 300ms ease;
}
.colorBlock.active {
    box-shadow: 0 0 10px 2px var(--c-color);
    transition: all 300ms ease;
}
.picker-container.active {
    position: absolute;
    z-index: 5000;
    left: -80px;
}
.picker-container.inactive{
    display: none;
}
.picker-container input,  .picker-container label{
    font-family: 'Zen Dots';
}
/*Responsive*/
@media screen and (max-width: 1200px){
    .board, .game-interface, footer{
        width: 80%;
    }
}

@media screen and (max-width: 768px){
    .board, .game-interface, footer{
        width: 100%;
    }
    .game-interface, footer{
        padding: 0 25px;
        box-sizing: border-box;
    }
    .game-buttons {
        flex-wrap: wrap;
    }
}